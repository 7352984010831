<template>
  <div class="banner">
    <div
      id="pcBanner"
      class="carousel slide carousel-vertical banner-carousel"
      data-ride="carousel"
    >
      <ol class="carousel-indicators">
        <li
          v-for="(bannerUrl, i) in bannerList"
          :key="i"
          data-target="#pcBanner"
          :data-slide-to="i"
          :class="{ active: i === 0 }"
        ></li>
      </ol>
      <div class="carousel-inner h-100">
        <div
          v-for="(bannerUrl, i) in bannerList"
          :key="i"
          :class="{ 'carousel-item': true, active: i === 0 }"
        >
          <img class="lazyload d-block w-100" :data-src="bannerUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lazyload from 'lazyload'
import { onMounted } from '@vue/runtime-core'
export default {
  name: 'DesktopBanner',
  props: {
    bannerList: Array,
  },
  setup() {
    onMounted(() => {
      const images = document.querySelectorAll('img.lazyload')
      new lazyload(images)
    })
  },
}
</script>

<style lang="scss" scoped></style>
