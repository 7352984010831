<template>
  <div class="container">
    <div class="d-flex justify-content-between th-my-22">
      <div class="d-flex justify-content-start align-items-center">
        <img class="feature-icon" src="/images/price@2x.png" alt="价格优惠，购买放心" />
        <span class="feature-desc th-ml-24 th-fs-16">价格优惠，购买放心</span>
      </div>
      <div class="d-flex justify-content-start align-items-center">
        <img class="feature-icon" src="/images/quality@2x.png" alt="品质保证，安心出行" />
        <span class="feature-desc th-ml-24 th-fs-16">品质保证，安心出行</span>
      </div>
      <div class="d-flex justify-content-start align-items-center">
        <img class="feature-icon" src="/images/products@2x.png" alt="产品多样，服务优质" />
        <span class="feature-desc th-ml-24 th-fs-16">产品多样，服务优质</span>
      </div>
      <div class="d-flex justify-content-start align-items-center">
        <img class="feature-icon" src="/images/contact@2x.png" alt="24小时客服，预订无忧" />
        <span class="feature-desc th-ml-24 th-fs-16">24小时客服，预订无忧</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Feature',
}
</script>

<style scoped>
.feature-desc {
  color: #666666;
}

.feature-icon {
  width: 30px;
}
</style>
