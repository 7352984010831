<template>
  <div class="footer text-white">
    <div class="container d-flex justify-content-between flex-wrap">
      <!-- 服务热线 start -->
      <div class="footer-container footer-container-contact-us">
        <p class="footer-subtitle text-white">服务热线</p>
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <p>公务出行：400 8840 055</p>
            <p>企业出行：400 0900 737</p>
          </div>
          <div>
            <p>个人出行：0755-8888 9999</p>
            <p>分销加盟：0755-8888 9999</p>
          </div>
        </div>
      </div>
      <!-- 服务热线 end -->
      <!-- 联系地址 start -->
      <div class="footer-container footer-container-address">
        <p class="footer-subtitle">联系地址</p>
        <p>特航总部：广东省深圳市南山区侨香路香年广场</p>
        <p>四川分公司：四川省成都市武侯区天府三街峰汇中心1号楼503室</p>
      </div>
      <!-- 联系地址 end -->
      <!-- 二维码 start -->
      <div class="footer-container footer-container-qrcode d-flex justify-content-center">
        <div class="text-center footer-qrcode-item th-mr-10">
          <img width="120" src="/images/tmc-app@2x.png" alt="特航商旅APP" />
          <p class="text-white th-mt-12">特航商旅APP</p>
        </div>
        <div class="text-center footer-qrcode-item th-ml-10">
          <img width="120" src="/images/wechat-account@2x.png" alt="特航航空公众号" />
          <p class="text-white th-mt-12">特航航空公众号</p>
        </div>
      </div>
      <!-- 二维码 end -->
      <!-- 站内链接 start -->
      <div class="footer-container footer-container-inner-link d-flex flex-wrap">
        <a class="text-white th-mr-8" href="./index.html">首页 | </a>
        <a class="text-white th-mr-8" href="./gp.html">公务出行 | </a>
        <a class="text-white th-mr-8" href="./tmc.html">企业出行 | </a>
        <a class="text-white th-mr-8" href="./sk.html">个人出行 | </a>
        <a class="text-white th-mr-8" href="./agent.html">分销加盟 | </a>
        <a class="text-white th-mr-8" href="./serve.html">企采服务 | </a>
        <a class="text-white th-mr-8" href="./about.html">关于我们</a>
      </div>
      <!-- 站内链接 end -->
      <!-- 友情链接 start -->
      <div class="footer-container footer-container-friendly-links">
        <p class="footer-subtitle text-white th-mr-10 th-mb-0">友情链接</p>
        <div class="d-flex flex-wrap">
          <a
            v-for="(link, i) in friendlyLinks"
            :key="i"
            class="text-white th-mr-5"
            target="_blank"
            :href="link.href"
            >{{ link.name }}</a
          >
        </div>
      </div>
      <!-- 友情链接 end -->
    </div>
  </div>
  <div class="copyright th-py-24 text-center">
    深圳市特航航空服务有限公司 版权所有 icp：粤ICP备14079210号-1
  </div>
</template>
<script>
import { friendlyLinks } from './data'

export default {
  name: 'DesktopFooter',
  setup() {
    return {
      friendlyLinks,
    }
  },
}
</script>

<style lang="scss" scoped>
.footer {
  overflow: hidden;
  background-color: #192033;
  padding: 30px 0;
}

.footer-container {
  margin-top: 50px;
  font-size: 14px;
  color: #fff;

  .footer-subtitle {
    font-size: 16px;
  }

  &-inner-link {
    order: 4;
    width: 100%;
    font-size: 16px;
  }

  &-address {
    order: 2;
    width: 400px;
  }

  &-contact-us {
    order: 1;
    width: 200px;
  }

  &-qrcode {
    order: 3;
    width: 300px;
  }

  &-friendly-links {
    order: 5;
    display: flex;
    a {
      margin-right: 6px;
    }
  }
}

.copyright {
  width: 100%;
  color: #999999;
  background-color: #1d1d1d;
  font-size: 16px;
}
</style>
