<template>
  <div class="content">
    <div class="container py-md">
      <h2 class="title" :style="titleStyle">{{ title }}</h2>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Content',
  props: {
    title: String,
    titleStyle: Object,
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 40px;
  color: #333333;
  line-height: 53px;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 48px;
}

@media (max-width: 576px) {
  .title {
    font-size: 24px;
    margin-bottom: 26px;
  }
}
</style>
