export const friendlyLinks = [
  { name: '中国航信', link: 'http://www.infosky.com.cn/publish/main/index.html' },
  { name: '中国民航', link: 'http://www.caac.gov.cn/index.html' },
  { name: '国际航协', link: 'https://www.iata-asd.com/asdcore/login.seam' },
  { name: '天合联盟', link: 'https://www.skyteam.com/zh' },
  { name: '星空联盟', link: 'https://www.staralliance.com' },
  { name: '中国东方航空', link: 'http://www.ceairgroup.com/' },
  { name: '中国海航', link: 'http://www.hnagroup.com/zh-cn/' },
  { name: '深圳航空', link: 'http://data.carnoc.com/corp/airline/zh.html' },
  { name: '企查查', link: 'https://www.qcc.com/' },
]
