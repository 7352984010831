export function getYearsFromJanuaryFirst(targetDate) {
  // 获取当前日期
  const currentDate = new Date();
  
  // 设置当前日期为当前年的1月1号
  currentDate.setMonth(0); // 0代表1月
  currentDate.setDate(1);
  
  // 设置目标日期为指定年的1月1号
  targetDate.setMonth(0); // 0代表1月
  targetDate.setDate(1);
  
  // 计算两个日期的年份差
  let yearsDifference = currentDate.getFullYear() - targetDate.getFullYear();
  
  // 如果当前日期还没有到达目标日期的年份的1月1号，则年份差减1
  if (currentDate < targetDate) {
    yearsDifference--;
  }
  
  return yearsDifference;
}
