<template>
  <div class="header-wrapper">
    <div class="mask"></div>
    <div class="header fixed-top">
      <div class="container" style="z-index: 300">
        <nav class="navbar navbar-expand-lg navbar-dark">
          <a class="navbar-brand navbar-wrapper" href="#">
            <img
              src="/images/tehang-logo@2x.png"
              width="153"
              height="40"
              class="d-inline-block align-top"
              alt="特航航空"
              loading="lazy"
            />
          </a>
          <a class="navbar-brand navbar-wrapper-m" href="#">
            <span class="tehang-logo d-inline-block align-top"></span>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <div class="navbar-nav">
              <a
                class="nav-item nav-link"
                :class="{ active: pageName === 'index' }"
                href="./index.html"
              >
                <img class="nav-icon" src="/images/mobile/nav-home.png" alt="" />
                <span>首页</span>
                <img class="nav-link-icon" src="/images/mobile/nav-link.png" alt="" />
              </a>
              <a class="nav-item nav-link" :class="{ active: pageName === 'gp' }" href="./gp.html">
                <img class="nav-icon" src="/images/mobile/nav-gp.png" alt="" />
                <span>公务出行</span>
                <img class="nav-link-icon" src="/images/mobile/nav-link.png" alt="" />
              </a>
              <a
                class="nav-item nav-link"
                :class="{ active: pageName === 'tmc' }"
                href="./tmc.html"
              >
                <img class="nav-icon" src="/images/mobile/nav-tmc.png" alt="" />
                <span>企业出行</span>
                <img class="nav-link-icon" src="/images/mobile/nav-link.png" alt="" />
              </a>
              <a class="nav-item nav-link" :class="{ active: pageName === 'sk' }" href="./sk.html">
                <img class="nav-icon" src="/images/mobile/nav-sk.png" alt="" />
                <span>个人出行</span>
                <img class="nav-link-icon" src="/images/mobile/nav-link.png" alt="" />
              </a>
              <a
                class="nav-item nav-link"
                :class="{ active: pageName === 'agent' }"
                href="./agent.html"
              >
                <img class="nav-icon" src="/images/mobile/nav-agent.png" alt="" />
                <span>分销加盟</span>
                <img class="nav-link-icon" src="/images/mobile/nav-link.png" alt="" />
              </a>
              <a
                class="nav-item nav-link"
                :class="{ active: pageName === 'service' }"
                href="./service.html"
              >
                <img class="nav-icon" src="/images/mobile/nav-yanxuan.png" alt="" />
                <span>企采服务</span>
                <img class="nav-link-icon" src="/images/mobile/nav-link.png" alt="" />
              </a>
              <a
                class="nav-item nav-link"
                :class="{ active: pageName === 'about' }"
                href="./about.html"
              >
                <img class="nav-icon" src="/images/mobile/nav-team.png" alt="" />
                <span>关于我们</span>
                <img class="nav-link-icon" src="/images/mobile/nav-link.png" alt="" />
              </a>
            </div>
          </div>
          <div class="toolbar">
            <div class="toolbar-item login">
              <a target="_blank" href="https://web.teyixing.com/passport/verification-login"
                >登录</a
              >
            </div>
            <div class="toolbar-item register">
              <a target="_blank" href="https://web.teyixing.com/passport/register">免费注册</a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  name: 'Navbar',
  props: {
    pageName: String,
  },
  mounted() {
    if ($('.header').length) {
      $(window).scroll(function () {
        120 < $('.header').offset().top
          ? $('.header').addClass('header-collapse')
          : $('.header').removeClass('header-collapse')
      })
    }

    $('.navbar-toggler').click(() => {
      const $header = $('.header-wrapper')
      const openCls = 'open'
      if ($header.hasClass(openCls)) {
        $header.removeClass(openCls)
      } else {
        $header.addClass(openCls)
      }
    })
  },
}
</script>

<style scoped>
@media (min-width: 577px) {
  .toolbar-item.register {
    background: linear-gradient(270deg, #fe7800 0%, #fea000 100%);
    border-radius: 8px;
    overflow: hidden;
  }

  .toolbar-item.register a {
    padding: 9px 32px;
    display: inline-block;
  }
}
</style>
